export interface ISubscribe {
    email: string;
    password: string;
}

export class Subscribe implements ISubscribe{
    email: string;
    password: string;
    
    constructor(email: string, password: string) {
     this.email = email;
     this.password = password;   
    }
}