
import {defineComponent} from "vue";
import store from "../../../BLL/store";

import Options from '../../components/Portal/Renault/Options.vue'
import OptionsTC from '../../components/Portal/TC/Options.vue'
import OptionsGilles from '../../components/Portal/Gilles/OptionsGilles.vue'
import {Ids} from '../../../config'
import {IClient} from '../../../config'
import Menu from "../../components/Portal/Menu.vue"

export default defineComponent({
    name: "Home",
    components:{
      Menu,
      Options,
      OptionsTC,
      OptionsGilles
    },
    data: function(){
      return{
        cli: {} as IClient | null,
        menuItem: 0 as number
      }
    },
    methods:{
      OnChange(item: string){
        if(this.cli)
          this.menuItem = this.cli.menu.indexOf(item);
      },
      async Logout(){
        await store.dispatch('userStore/Logout');
        this.$router.push('/');
      }
    },
    async created(){
      this.cli = Ids.find(i=>i.id.includes(store.getters['userStore/loggedId'])) ?? null;
    }
});
