
import { IControl } from '../Models/Models';
import { defineComponent } from "vue";

export default defineComponent({
  name: 'DynamicComponent',
  props:{
    inputModel: {} as () => IControl
  },
  data: function(){
    return{
      input: this.inputModel as IControl
    }
  }
});
