
import {parseModel} from '../ModelParser'
import {GetFromView} from '../ModelParser'

import DynamicComponent from './DynamicComponent.vue'
import Datepicker from '@vuepic/vue-datepicker';
import DynamicSelect from './DynamicSelect.vue'
import DynamicTextArea from './DynamicTextArea.vue'
import DynamicFile from './DynamicFile.vue'

import '@vuepic/vue-datepicker/dist/main.css';
import { IControl, IResult } from '../Models/Models';
import { defineComponent } from "vue";

export default defineComponent({
  name: 'DynamicPage',
  components:{
    DynamicComponent,
    Datepicker,
    DynamicSelect,
    DynamicTextArea,
    DynamicFile
  },
  props:[    
    'objet',
    'validate',
    'pass',
    'name',
    'desc',
    'repeatPlaceholder'
  ],
  emits:['action'],
  data: function(){
    return{
      inputs : null as IControl[]|null,
      error: this.validate
    }
  },
  methods:{
    Submit(){
        if(this.inputs)
        {
          //we put the style off all controls back to default
          this.inputs.forEach(i => i.setStyle());
          //we remove the error message
          this.error = "";

          //we collect the info of the view (error, object)
          //by passing:
          //  -> the inputs
          //  -> our object in original state
          //  -> the inputs (original object parsed in inputs)
          const res = GetFromView(this.$refs, this.objet, this.inputs) as IResult;

          //if we have an error, we add an error class to every control who is in error
          if(res.errors)
            res.errors.forEach(i => this.ClasseErreur(i.control));

          //we emit the action
          this.$emit('action', res);
        }
    },
    ClasseErreur(nomControl: string){
      const ctrl = this.inputs?.find(i => i.name == nomControl) as IControl;
      
      if(ctrl)
        ctrl.styleClass = ctrl.styleClass + " TumiInputError";
    }
  },
  created(){
    this.inputs = parseModel(this.objet);
  },
  watch:{
    objet(value: any){
      if(value)
        this.inputs = parseModel(value);
    },
    validate(value: string){
      this.error = value;
    }
  }
});
