
import { defineComponent } from "vue";
import { IClient } from "../../../../config"
import Content from "./Content.vue"

export default defineComponent({
    name: "OptionsGilles",
    components:{
      Content
    },
    props:{
      Cli: {} as () => IClient | null,
      menuItem: {} as () => number
    }
});
