
import { defineComponent } from "vue";

export default defineComponent({
    name: "Menu",
    props:['name', 'menu'],
    data: function(){
      return{
        nom: null as string|null
      }
    },
});
