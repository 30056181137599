
import store from "../../../../BLL/store";
import { defineComponent } from "vue";
import {Config} from "../../../../DAL/Config"

export default defineComponent({
  name: "Afspraken",
  data: function(){
    return{
      path: '/Portal/GetAfspraken' as string,
    }
  },
  methods:{
    async DownloadBerichten(){
      await store.dispatch('dynamicStore/getDynamic', '/Common/CreateDownloadCode')
      const code = (store.state as any).dynamicStore['/Common/CreateDownloadCode'];
      window.open(Config.Url() + "/PortalTC/GetExcelMessages?code=" + code);
    },
    async DownloadNews(){
      await store.dispatch('dynamicStore/getDynamic', '/Common/CreateDownloadCode')
      const code = (store.state as any).dynamicStore['/Common/CreateDownloadCode'];
      window.open(Config.Url() + "/PortalTC/GetExcelNews?code=" + code);
    }
  }
});
