import {IConnectedLogin} from '../models/IConnectedLogin'

export function authHeader(headers : Headers) {
    const userStorage = localStorage.getItem('user');

    if(!userStorage)
        return headers;

    const user = JSON.parse(userStorage) as IConnectedLogin;

    if (user && user.TOKEN){
        headers.set('Authorization', 'Bearer ' + user.TOKEN);
        return headers;
    }
    else
        return headers;
}