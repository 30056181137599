
import store from "../../BLL/store";
import { defineComponent } from "vue";
import { Subscribe } from "../../BLL/models/Subscribe";

export default defineComponent({
    name: "SignUp",
    data: function(){
      return{
        mail : "" as string,
        pass: "" as string,
        pass2: "" as string,
        error: "" as string
      }
    },
    methods:{
      async SignUp(){
        this.error = "";

        if(!this.mail || !this.pass || !this.pass2)
        {
          this.error = "All fields are required.";
          return;
        }  

        if(this.pass != this.pass2)
        {
          this.error = "Passwords don't match.";
          return;
        }

        await store.dispatch('userStore/SignUp', new Subscribe(this.mail, this.pass));

        const error = (store.state as any).userStore.error;

        if(error)
        {
          this.error = error;
          return;
        }

        this.SignedUp();
      },
      async SignedUp()
      {
        //Logic when logged in
        this.error = "Email sent!";
      }
    }
});
