
import { defineComponent } from "vue";

import Left from "../../src/components/Home/Left.vue"
import Right from "../../src/components/Home/Right.vue"
import Contact from "../../src/components/Home/Contact.vue"

export default defineComponent({
    name: "Home",
    components:{
      Left, 
      Right,
      Contact
    },
    methods:{
      Home(){
        window.scrollTo(0, 0);
      },
      Projects(){
        const yOffset = -100; 
        const element = document.getElementById("Projects");
        if(element)
        {
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({top: y, behavior: 'smooth'});
        }
      },
      Contact(){
        const yOffset = -60; 
        const element = document.getElementById("Contact");
        if(element)
        {
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({top: y, behavior: 'smooth'});
        }
      },
      What(){
        const yOffset = -60; 
        const element = document.getElementById("What");
        if(element)
        {
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({top: y, behavior: 'smooth'});
        }
      }
    },
});
