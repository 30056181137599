import { vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["placeholder", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.input)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.input.divClass)
      }, [
        _withDirectives(_createElementVNode("textarea", {
          class: _normalizeClass(_ctx.input.styleClass),
          placeholder: _ctx.input.placeholder,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.input.value) = $event)),
          disabled: _ctx.input.disabled
        }, null, 10, _hoisted_1), [
          [_vModelText, _ctx.input.value]
        ])
      ], 2))
    : _createCommentVNode("", true)
}