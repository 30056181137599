
import store from "../../BLL/store";
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import { NewPass } from "../../BLL/models/NewPass";
import router from "@/router";

export default defineComponent({
    name: "Home",
    data: function(){
      return{
        pass : "" as string,
        pass2 : "" as string,
        error: "" as string,
        criticalError : "" as string,
        start : false as  boolean,
        guid : "" as string,
      }
    },
    methods:{
      async SetNewPass(){
        this.error = "";

        if(!this.pass || !this.pass2)
        {
          this.error = "All fields are required";
          return;
        }  

        if(this.pass != this.pass2)
        {
          this.error = "Passwords don't match";
          return;
        }

        await store.dispatch('userStore/SaveNewPass', new NewPass(this.guid, this.pass));

        const error = (store.state as any).userStore.error;

        if(error)
        {
          this.error = error;
          return;
        }

        this.NewPassSet();
      },
      async NewPassSet()
      {
        //Logic when logged in
        this.error = "New pass set.";
        router.push('/');
      }
    },
    async created(){
      this.criticalError = "";

      this.guid = String(useRoute().params.id);
      await store.dispatch("userStore/CheckIfNewPassGuidExists", this.guid);


      const criticalError = (store.state as any).userStore.error

      if(criticalError)
      {
        this.criticalError = criticalError;
        return;
      }

      this.start = true;
    }
});
