import { apiErrorCheck } from "../../../BLL/helpers/handleResponse";
import { Config } from "../../Config"
import { authHeader } from "../../../BLL/helpers/auth-header";

export const portalService = {
    GetMenu
}

async function GetMenu(idLogin: number): Promise<void>{
    const headers : HeadersInit = new Headers(); 
    headers.set('Content-Type', 'application/json')
    
    const requestOptions = { 
        method: 'GET', 
        headers: authHeader(headers),
    } as RequestInit;

    return apiErrorCheck(Config.Url() + `/Portal/GetMenu?idLogin=` + idLogin, requestOptions)
}