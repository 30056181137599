import { Commit } from "vuex"
import { IState } from "../../models/IState";
import { portalService } from '../../../DAL/Services/Portal/portal.service'
import store from '../../store';

export interface portalState extends IState{
    menu: string[] | null,
}

export const portalStore = {
    namespaced: true,
    state: () => ({
    }) as portalState,
    actions:{
        async GetMenu({commit}:{commit: Commit}): Promise<void>{
            await portalService.GetMenu(store.getters['userStore/loggedId'])
                .then(
                    data => commit('SuccesMenu', data),
                    error => commit('SetError', error))  
        }
    },
    mutations: {
        SetError(state : portalState, error : string){
            state.error = error;
        },
        SuccesMenu(state: portalState, menu: string[]){
            state.menu = menu;
        },
        SetErrorToNull(state: portalState){
            state.error = null;
        }
    }
}