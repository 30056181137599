
import store from "../../BLL/store";
import { defineComponent } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
    name: "ConfirmMail",
    data: function(){
      return{
        error: "" as string
      }
    },
    methods:{
      async EmailConfirmed()
      {
        this.error = "Email Confirmed!";
      }
    },
    async created(){
      this.error = "";

      const guid = useRoute().params.id;
      await store.dispatch("userStore/ConfirmEmail", guid);

      const error = (store.state as any).userStore.error

      if(error)
      {
        this.error = error;
        return;
      }

      this.EmailConfirmed();
    }
});
