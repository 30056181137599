import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "col-12 col-md-9 p-5",
  style: {"background-color":"#f2f2f2"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Content = _resolveComponent("Content")!
  const _component_Afspraken = _resolveComponent("Afspraken")!

  return (_ctx.Cli)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.menuItem == 0)
          ? (_openBlock(), _createBlock(_component_Content, {
              key: 0,
              PathGet: _ctx.Cli.get,
              PathSet: _ctx.Cli.set
            }, null, 8, ["PathGet", "PathSet"]))
          : _createCommentVNode("", true),
        (_ctx.menuItem == 1)
          ? (_openBlock(), _createBlock(_component_Afspraken, { key: 1 }))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}