import { ToUpper } from "./fonctions";
import {IError} from '../models/Error'

export async function apiErrorCheck<T>(url:string, requestOptions: RequestInit):Promise<T>{
    //if fetch is ok -> return the response
    try{
        return await api(url, requestOptions)
            .then(
                data => { return data as Promise<T>; },
                error => { return Promise.reject(error); }
            );
    }
    //if fetching not ok return error.
    catch(e){
        return Promise.reject(String(e));
    }
}

export async function api<T>(url: string, requestOptions: RequestInit): Promise<T> {
    return fetch(url, requestOptions)
        //Get response
        .then(async response => {
            if(!response.ok){
                //if BADREQUEST or UNAUTHORIZATED type of object IERROR
                if(response.status === 400 || response.status === 401)
                    return response.text()
                        .then(text => {
                            //get message from IERROR object
                            return Promise.reject((JSON.parse(text) as IError).message)
                        });

                //If 500 intern error we don't show the message (could have sensible info)
                if(response.status === 500)
                {
                    //Do log something
                    //....
                    return Promise.reject("An error occured.");
                }

                return Promise.reject("Something unusual happened");
            }
            
            //if response is ok
            const contentType = response.headers.get("content-type");

            //Check if text response or Json and coverting
            if (contentType && contentType.indexOf("application/json") !== -1)
                return await response.json() as Promise<T>;
            else
                return response.text();
        })
        .then(
            data => { return ToUpper(data); },
        );
}