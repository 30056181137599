
import store from "../../../BLL/store";
import { defineComponent } from "vue";
import DynamicPage from "../../../BLL/Tumi/Components/DynamicPage.vue"
import {IResult} from "../../../BLL/Tumi/Models/Models"
import {IError} from "../../../BLL/Tumi/Models/Models"
import {validateEmail} from "../../../BLL/helpers/fonctions"

export default defineComponent({
    name: "Home",
    components:{
      DynamicPage
    },
    data: function(){
      return{
        activeObject: null as any,
        error: "" as string,
        pass: "" as string 
      }
    },
    methods:{
      async Save(res: IResult){
        this.error = "";
        this.pass = "";

        console.log(res);

        if(res.errors.length > 0)
        {
          if(res.errors.find((i :IError)=> i.error == "required"))
            this.error = "Those fields are required.";
          return;
        }

        if(!validateEmail(res.object.CONTACT_EMAIL))
        {
          this.error = "Email must be valid";
          return;
        }

        this.activeObject = null;
        await store.dispatch("dynamicStore/setDynamic",{"pathSet":"/portal/setContact", "retour":res.object})
        this.GetContact();
        this.pass = "ok";
      },
      async GetContact(){
        await store.dispatch('dynamicStore/getDynamic','/portal/getcontactempty');
        this.activeObject = (store.state as any).dynamicStore['/portal/getcontactempty'];
      }
    },
    async created(){
      this.GetContact();
    }
});
