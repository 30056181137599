import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "col-12 col-md-2 position-md-sticky align-items-center vh-md-90 mx-auto",
  style: {"top":"0"}
}
const _hoisted_2 = { class: "mt-3 mb-3" }
const _hoisted_3 = { class: "d-grid gap-2" }
const _hoisted_4 = { class: "list-group list-group-flush mt-3" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.name), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        type: "button",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('Logout'))),
        class: "btn btn-primary"
      }, "Logout")
    ]),
    _createElementVNode("ul", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu, (men) => {
        return (_openBlock(), _createElementBlock("li", {
          key: men,
          onClick: ($event: any) => (_ctx.$emit('change', men))
        }, _toDisplayString(men), 9, _hoisted_5))
      }), 128))
    ])
  ]))
}