
import { IControl } from '../Models/Models';
import { defineComponent } from "vue";

export default defineComponent({
  name: 'DynamicSelect',
  props:['inputModel'],
  data: function(){
    return{
      input: this.inputModel as IControl
    }
  }
});
