
import { defineComponent } from "vue";

export default defineComponent({
    name: "Menu",
    props:[
      'img', 
      'title',
      'text',
      'type',
      'link']
});
