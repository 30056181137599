import { apiErrorCheck } from "../../../BLL/helpers/handleResponse";
import { Config } from "../../Config"
import { AuthenticateRequest } from "../../../BLL/models/AuthenticateRequest";
import { ISubscribe } from "../../../BLL/models/Subscribe";
import { INewPass } from "../../../BLL/models/NewPass";
import { authHeader } from "../../../BLL/helpers/auth-header";

export const userService = {
    SignUp,
    ConfirmEmail,
    RetrievePass,
    SaveNewPass,
    Authenticate,
    CheckIfNewPassGuidExists,
    Logged
}

async function Logged(): Promise<void>{

    const headers : HeadersInit = new Headers();
    headers.set('Content-Type', 'application/json')
    
    const requestOptions = {
        method: 'GET', 
        headers: authHeader(headers),
    } as RequestInit;

    return apiErrorCheck(Config.Url() + `/User/Test`, requestOptions)
}

async function CheckIfNewPassGuidExists(guid : string): Promise<void>{
    const requestOptions = {
        method: 'Get', 
    };

    return apiErrorCheck(Config.Url() + `/User/CheckIfNewPassGuidExists?Guid=` + guid, requestOptions)
}

async function SignUp(data : ISubscribe): Promise<void>{
    const requestOptions = {
        method: 'POST', 
        body: JSON.stringify(data),
        headers: {'Content-Type' : 'application/json'}
    };

    return apiErrorCheck(Config.Url() + `/User/SignUp`, requestOptions)
}

async function ConfirmEmail(guid : string): Promise<void>{
    const requestOptions = {
        method: 'GET',
    };

    return apiErrorCheck(Config.Url() + `/User/ConfirmEmail?Guid=` + guid, requestOptions)
}

async function RetrievePass(email : string): Promise<void>{
    const requestOptions = {
        method: 'GET',
    };

    return apiErrorCheck(Config.Url() + `/User/RetrievePass?Email=` + email, requestOptions)
}

async function SaveNewPass(data : INewPass): Promise<void>{
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {'Content-Type' : 'application/json'}
    };

    return apiErrorCheck(Config.Url() + `/User/SaveNewPass`, requestOptions)
}


async function Authenticate<T>(data : AuthenticateRequest): Promise<T>{
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {'Content-Type' : 'application/json'}
    };
    
    return apiErrorCheck(Config.Url() + `/User/Authenticate`, requestOptions);
}