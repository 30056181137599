export interface INewPass {
    guid: string;
    password: string;
}

export class NewPass implements INewPass{
    guid: string;
    password: string;

    constructor(guid : string, password :string) {
        this.guid = guid;
        this.password = password;
    }
}