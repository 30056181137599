
import { defineComponent } from "vue";
import store from "../../../../BLL/store";
import DynamicPage from '../../../../BLL/Tumi/Components/DynamicPage.vue'
import { IResult } from "../../../../BLL/Tumi/Models/Models";

export default defineComponent({
    name: "Content",
    props:{
      PathGet: {} as () => string | null,
      PathSet: {} as () => string | null
    },
    components:{
      DynamicPage
    },
    data: function(){
      return{
        activeObject : null as any,
        error: "" as string
      }
    },
    methods:{
      async Save(obj: IResult){
        if(obj.errors.length > 0){
          const er = obj.errors.find(i=>i.error == "required");

          if(er)
            this.error = "Alle velden zijn verplicht.";
            return;
        }

        if(this.PathSet){
          this.activeObject = null;
          const pathSet = this.PathSet; 
          const retour = obj.object;
          await store.dispatch('dynamicStore/setDynamic', {pathSet,retour});
          this.LoadObject();
        }
      },
      async LoadObject(){
        if(this.PathGet){
          await store.dispatch('dynamicStore/getDynamic',this.PathGet);
          this.activeObject = (store.state as any).dynamicStore[this.PathGet];
        }
      }
    },
    created(){
      this.LoadObject();
    }
});
