import { vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["placeholder", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.input.divClass)
  }, [
    _withDirectives(_createElementVNode("input", {
      type: "text",
      class: _normalizeClass(_ctx.input.styleClass),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.input.value) = $event)),
      placeholder: _ctx.input.placeholder,
      disabled: _ctx.input.disabled
    }, null, 10, _hoisted_1), [
      [_vModelText, _ctx.input.value]
    ])
  ], 2))
}