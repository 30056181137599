
import store from '../../../../BLL/store/index'
import { defineComponent } from "vue";
import {IAfspraak} from "../../../../BLL/models/Renault/Afspraak"
import {Config} from "../../../../DAL/Config"
import {getDate_ddmmyyyy} from "../../../../BLL/helpers/fonctions"

export default defineComponent({
  name: "Afspraken",
  data: function(){
    return{
      Afspraken:null as IAfspraak[]|null,
      path: '/Portal/GetAfspraken' as string,
      open: 0 as number
    }
  },
  methods:{
    DateFormat(valeur:string): string{
      return getDate_ddmmyyyy(new Date(valeur));
    },
    async Download(){
      await store.dispatch('dynamicStore/getDynamic', '/Common/CreateDownloadCode')
      const code = (store.state as any).dynamicStore['/Common/CreateDownloadCode'];
      window.open(Config.Url() + "/Portal/GetExcelAfspraken?code=" + code);
    },
    async LoadAfspraken(){
      await store.dispatch('dynamicStore/getDynamic', this.path);
      this.Afspraken = (store.state as any).dynamicStore[this.path];
    },
    Ouvrir(id: number){
      if(this.open == id){
        this.open = 0
        return;
      }

      this.open = id;
    }
  },
  async created(){
    this.LoadAfspraken();
  }
});
