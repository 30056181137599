
import { defineComponent } from "vue";

export default defineComponent({
    name: "Menu",
    props:[
      'img', 
      'title',
      'text',
      'type',
      'link'],

    data:function(){
      return{
        imgWorked : "" as string,
        visible:false as boolean
      }
    },
    created(){
      this.imgWorked = this.img ? 'img-fluid ' + this.img : 'img-fluid'

      if(this.img)
      {
        window.addEventListener('scroll', () => {
          var element = document.getElementsByClassName(this.img)[0];
          var position = element?.getBoundingClientRect();

          if(position){
            if(position.top < window.innerHeight && position.bottom >= 0)
              this.visible = true;
            else
              this.visible = false;
          }
        });
      } 
    },
    watch:{
      visible(e){
        if(e)
          console.log(e);
      }
    }
});
