export interface IClient{
    id: number[];
    nom: string;
    menu: string[],
    get: string | null,
    set: string | null
}

export const Ids = [
    {"id":[3], "nom":"Renault De Vuyst", "menu": ["Content website","Afspraken"], get: "/Portal/GetContentRenault", set: "/Portal/SetContentView"},
    {"id":[4], "nom":"ToniCoppers", "menu": ["Data Downloaden"], get: null, set: null},
    {"id":[1], "nom":"Gilles", "menu": ["Promotions"], get: "/PortalGilles/GetContentGilles", set: "/PortalGilles/SetContentView"}
] as IClient[]