export const styleClass = "form-control" as  string;

export interface IResult{
    errors:IError[];
    object:any
}

export interface IError{
    control:string;
    error:string;
}

export interface IControl{
    name: string;
    placeholder: string;
    styleClass: string;
    divClass: string;
    value: any;
    disabled: boolean;
    type : string | null;
    options: [] | null;
    explanation: string | null;
    fileTypes: string[] | null;
    fileCount: number|null;
    setStyle():void;
}

export class Control implements IControl{
    name: string;
    placeholder: string;
    styleClass = "" as string;
    divClass: string;
    value: any;
    disabled: boolean;
    type : string | null;
    options: [] ;
    explanation: string | null;
    fileTypes: string[] | null;
    fileCount: number|null;

    constructor(
        name: string, 
        placeholder:string, 
        divClass:string,
        value: any,
        disabled: boolean,
        type: string|null,
        options: [],
        explanation: string | null,
        fileTypes: string[] | null,
        fileCount: number|null){

        this.name = name;
        this.placeholder = placeholder;
        this.divClass = divClass;
        this.value = value;
        this.disabled = disabled;
        this.type = type;
        this.options = options;
        this.explanation = explanation;
        this.setStyle();
        this.fileCount = fileCount;
        this.fileTypes = fileTypes;
    }

    setStyle(): void{
        this.styleClass = styleClass + " " +this.name;
    }
}