import { apiErrorCheck } from '../../../BLL/helpers/handleResponse'
import { Config } from '../../Config'
import { authHeader } from '../../../BLL/helpers/auth-header'

export const dynamicService = {
    getDynamic,
    setDynamic,
    setDynamicFiles
};

async function getDynamic(path: string) {
    const headers : HeadersInit = new Headers();
    headers.set('Content-Type', 'application/json')

    const requestOptions = {
        method: 'GET',
        headers: authHeader(headers)
    };

    return apiErrorCheck(Config.Url() + path, requestOptions)
}

async function setDynamic(path: string, obj: any) {
    const headers : HeadersInit = new Headers();
    headers.set('Content-Type', 'application/json')

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(obj),
        headers: authHeader(headers)
    };

    return apiErrorCheck(Config.Url() + path, requestOptions)
}

async function setDynamicFiles(path:string, obj: any){
    const formData = new FormData();

    for(const prop in obj)
        formData.append(prop, obj[prop]);

    obj.TUMIFILES.forEach((i:File) => formData.append(i.name, i));

    const requestOptions = {
        method: 'POST',
        body: formData, 
        headers: authHeader(new Headers()),
    } as RequestInit;

    return apiErrorCheck(Config.Url() + path, requestOptions)
}