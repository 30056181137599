
import { defineComponent } from 'vue';
import { IControl } from '../Models/Models';
import { Config } from '../../../DAL/Config';
import { isPicture } from '../../helpers/fonctions'
import { isExtension } from '../../helpers/fonctions'

export default defineComponent({ 
  name: 'FilesForm',
  emits:['CloseForm'],
  props:['inputModel','number','types'],
  data: function(){
    return{
      input: this.inputModel as IControl,
      hightlight : false,
      files: [] as File[],
      errorFiles: "" as string,

      newPictures: [] as {name:string, file:string}[],
      newFiles: [] as string[],
      oldPictures: [] as string[],
      oldFiles: [] as string[],
      path: "/Common/GetFile?filename=" as string,

      numberAdmitted: this.number as number|null,
      typesAdmitted: this.types as string[]|null
    }
  },
  methods:{
    IsPic(name:string){
      return isPicture(name);
    },
    GetPicture(name:string){
      return Config.Url() + this.path + name;
    },
    Download(name: string){
      if(name.includes('/'))
        window.open(Config.Url() + this.path + name);
      else{
        const nomsAvecFolder = this.input.value.split(";");

        nomsAvecFolder.forEach((element:string) => {
          if(element.split("/")[1] == name)
            window.open(Config.Url() + this.path + element);
        });
      }
    },
    DeleteOldPicture(name: string){
      this.DeleteValue(name);
      this.oldPictures = this.oldPictures.filter((i: string) => i != name);
    },
    DeleteOldFile(name: string){
      this.DeleteValue(name);
      //refresh view
      this.oldFiles = this.oldFiles.filter((i: string)=>i != name);
    },
    DeleteValue(name: string){
      //get list of names
      const noms = this.input.value.split(';');
      //getout name we want to delete
      const nomsSplit = 
        name.includes('/') ? 
          noms.filter((i:string) => i != name):
          noms.filter((i:string) => i.split('/')[1] != name);
      
          //if something is left, rejoin the names
      this.input.value = nomsSplit.length > 0 ? nomsSplit.join(';') : null;
    },
    DeleteNewPicture(name:string){
      this.DeleteFile(name);
      this.newPictures = this.newPictures.filter((i: {name:string, file:string})=>i.name != name);
    },
    DeleteNewFile(name:string){
      this.DeleteFile(name);
      this.newFiles.splice(this.newFiles.indexOf(name),1);
    },
    DeleteFile(name: string){
      for(var x = 0; x<this.files.length; x++)
        if(this.files[x].name == name)
          this.files.splice(x, 1);
    },
    openFileSelection(){
      (this.$refs.fileInput as HTMLElement).click();
    },
    onDragOver(event: DragEvent): void {
      event.preventDefault();
      this.hightlight = true;
    },
    onDragLeave(): void {
      this.hightlight = false;
    },
    CheckIfEnoughPlace(filesCount: number): boolean{
      if(!this.numberAdmitted)
        return true;
      
      if(this.newPictures.length + this.newFiles.length + this.oldPictures.length + this.oldFiles.length + filesCount <= this.numberAdmitted)
        return true;

      return false;
    },
    CheckIfAuthorizedExtentions(files: FileList): boolean{
      if(!this.typesAdmitted)
        return true;
      
      let retour = true;

      for (var i = 0; i < files.length; i++) {
        if(!isExtension((files.item(i) as File).name, this.typesAdmitted ?? []))
          retour = false;
      }

      return retour;
    },
    pushFiles(files: FileList){
      if(!this.CheckIfEnoughPlace(files.length)){
        alert("You're not authorized to upload that number of files in total.")
        return;
      }

      if(!this.CheckIfAuthorizedExtentions(files)){
        alert("You're not authorized to upload this type of file.")
        return;
      }

      let result: File[] = [];

      for (var i = 0; i < files.length; i++) {
        result.push(files.item(i) as File);
      }

      //Check si nom en double
      let stop = false;
      const nomsFichiersExistants = [] as string[];
      nomsFichiersExistants.push(...this.oldFiles);
      nomsFichiersExistants.push(...this.newFiles);
      nomsFichiersExistants.push(...this.newPictures.map((i: {name:string, file:string})=>i.name));
      nomsFichiersExistants.push(...this.oldPictures.map((i: string) => i.split("/")[1]));

      result.forEach((element:File) => {
        if(nomsFichiersExistants.includes(element.name))
          stop = true;
      });

      if(stop){
        alert("One of the files you've tried to upload already exist in the uploaded files. " + 
              "Delete the uploaded file or change the name of the file you're trying to upload.");
        return;
      }

      this.files.push(...result);
      this.RefreshFileNames();
    },
    onDrop(event: DragEvent): void {
      event.preventDefault();
      const files = event.dataTransfer?.files;
      if(files)
        this.pushFiles(files);
        
      this.hightlight = false;
    },
    onFileChanged(event: any) {
      this.pushFiles(event.target.files);
      (this.$refs.fileInput as HTMLInputElement).value = "";
    },
    RefreshFileNames(){

      /////////////
      //OLD FILES//
      /////////////

      //if we have something, split it in names
      const noms =  this.input.value ? this.input.value.split(";") : [];

      this.oldPictures = [];
      this.oldFiles = [];

      //foreach name separate the picture from the files
      noms.forEach((element: string) => {
        if(this.IsPic(element))
          this.oldPictures.push(element);
        else
          this.oldFiles.push(element.split('/')[1]);
      }); 

      /////////////
      //NEW FILES//
      /////////////

      this.newFiles = [];
      this.newPictures = [];

      //foreah file separate the pictures from the files
      this.files.forEach((element :File) => {
        if(this.IsPic(element.name)){
          
          //save the name and content of the picture in a list
          const reader = new FileReader();
          
          reader.addEventListener("load", () => { 
            var name = element.name;
            var file = reader.result as string
            this.newPictures.push({name, file})
          }, false);
          
          reader.readAsDataURL(element);
        }
        else
          this.newFiles.push(element.name);  
      });
    }
  },
  created(){
    this.RefreshFileNames();
  }
});


