export interface IAuthenticateRequest {
    username: string;
    password: string;
}

export class AuthenticateRequest implements IAuthenticateRequest{
    username: string;
    password: string;
    
    constructor(username : string, password : string) {
        this.username = username;
        this.password = password;
    }
}
