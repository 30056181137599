import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "TumiFond",
  style: {"background-color":"white"}
}
const _hoisted_2 = { class: "container-fluid px-md-0 TumiFond" }
const _hoisted_3 = { class: "row justify-content-between gx-0 mx-md-0 TumiFond" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Menu = _resolveComponent("Menu")!
  const _component_Options = _resolveComponent("Options")!
  const _component_OptionsTC = _resolveComponent("OptionsTC")!
  const _component_OptionsGilles = _resolveComponent("OptionsGilles")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Menu, {
          name: _ctx.cli?.nom,
          menu: _ctx.cli?.menu,
          onLogout: _cache[0] || (_cache[0] = ($event: any) => (_ctx.Logout())),
          onChange: _ctx.OnChange
        }, null, 8, ["name", "menu", "onChange"]),
        (_ctx.cli?.nom == 'Renault De Vuyst')
          ? (_openBlock(), _createBlock(_component_Options, {
              key: 0,
              Cli: _ctx.cli,
              menuItem: _ctx.menuItem
            }, null, 8, ["Cli", "menuItem"]))
          : _createCommentVNode("", true),
        (_ctx.cli?.nom == 'ToniCoppers')
          ? (_openBlock(), _createBlock(_component_OptionsTC, {
              key: 1,
              Cli: _ctx.cli,
              menuItem: _ctx.menuItem
            }, null, 8, ["Cli", "menuItem"]))
          : _createCommentVNode("", true),
        (_ctx.cli?.nom == 'Gilles')
          ? (_openBlock(), _createBlock(_component_OptionsGilles, {
              key: 2,
              Cli: _ctx.cli,
              menuItem: _ctx.menuItem
            }, null, 8, ["Cli", "menuItem"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}