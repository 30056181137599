export function isPicture(fileName:string){
    return isExtension(fileName, ["jpg","jpeg","png","gif"]);
}

export function isExtension(fileName:string, extensions: string[]){
    let retour = false;
    
    extensions.forEach((element: string) =>{
        if(fileName.endsWith('.' + element))
            retour = true;
    });

    return retour;
}


export function divisionEnChunk<T>(liste: Array<T>, nombre: number):Array<Array<T>>{
    const zones = [];

    for (let i = 0; i < liste.length; i += nombre) {
        zones.push(liste.slice(i, i + nombre));
    }

    return zones;
}

export function EnMB(kb : number): string{
    return kb / 1e+6 + "Mb";
}

export function validateEmail(email:string){
    return String(email)
        .toLowerCase()
        .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
}

export function getDate_forApi(date: Date) {
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2,'0');
    const dd = String(date.getDate()).padStart(2,'0');

    return `${yyyy}-${mm}-${dd}`
}

export const ToUpper = (obj: any): any => {
    if (typeof obj !== 'object') {
        return obj;
    }
    if (Array.isArray(obj)) {
        return obj.map(ToUpper);
    }
    if (obj === null) {
        return null;
    }

    const entries = Object.entries(obj);
    const mappedEntries = entries.map(
        ([k, v]) => [k.toUpperCase(), v] as const
    );

    return Object.fromEntries(mappedEntries);
};

export function getDate_ddmmyyyy(date: Date) : string{
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2,'0');
    const dd = String(date.getDate()).padStart(2,'0');

    return `${dd}/${mm}/${yyyy}`
}